<template>
	<basePopup :okFunc="edit" :title="title" cancelText="닫기" okText="수정">
		<div>
			<h6>기본 정보</h6>
			<vtb :data="dealInfo">
				<!--딜 요청상태 커스텀-->
				<template #dealStatus>
					<div class="vSelectWrap flex">
						<vSelect
							class="w-60 mr-2"
							v-model="input.dealStatus"
							:clearable="false"
							:options="dealStatusOpts"
						/>
						<b-button @click="editDealStatus(true)">상태변경</b-button>
					</div>
				</template>
				<template #chargeAdd>
					<b-form-input
						class="w-30 inline-block"
						v-model.trim="input.chargeAdd"
						@keyup="input.chargeAdd = num(input.chargeAdd)"
						:disabled="!isEditableCharge"
					/>
					<p class="inline-block ml-1">% &nbsp;&nbsp;&nbsp;딜 수락/ 시작일 전 입력가능</p>
				</template>
				<template #dateTime="{ v }">
					<datePicker class="w-40 inline-block" :model="`input.${v.cont}Date`" />
					<b-form-input class="w-40 ml-2 inline-block" v-model.trim="input[v.cont + 'Time']" />
				</template>
				<template #switch="{ v }">
					<b-form-checkbox class="mt-2 absolute" v-model="input[v.cont]" switch />
				</template>
			</vtb>
			<b-button class="px-12" @click="edit">수정</b-button>

			<h6 class="mt-4 w-1/1">핫티스트 정보</h6>
			<vtb :data="hottistInfo" />

			<h6 class="mt-4">상품그룹 정보</h6>
			<vtb :data="productGroupInfo" />

			<h6 class="mt-4">협찬품 정보</h6>
			<vtb :data="productInfo" />
		</div>
	</basePopup>
</template>

<script>
import basePopup from 'comp/local/basePopup'
import collapse from 'comp/collapse'
import selectList from 'comp/local/selectList'

const defInput = {
	startDate: '',
	startTime: '',
	endDate: '',
	endTime: '',
	chargeAdd: 0,
	dealStatus: '',
	isSupportAdvertisement: true,
	isAutoEncore: false,
}

export default {
	components: { basePopup, collapse, selectList },
	props: {
		statusOpts: { default: false },
		menuTabType: { default: false },
		deliveryCompany: { default: false },
		deliveryStatus: { default: false },
		productDeliveryType: { default: false },
		hottistType: { default: false },
		contentQualityOpts: { default: false },
		contentCategoryOpts: { default: false },
	},
	data() {
		return {
			title: '',
			isShow: false,
			now: new Date(),

			dealInfo: [],
			hottistInfo: [],
			productGroupInfo: [],
			productInfo: [],
			input: { ...defInput },

			originItem: {},
		}
	},
	methods: {
		open(item) {
			this.originItem = item
			this.isShow = true

			this.input = { ...defInput }
			this.dealInfo = []
			this.hottistInfo = []
			this.productGroupInfo = []
			this.productInfo = []
			postApi('api/backoffice/dealSelect/dealDetail', {
				id: item.idx,
			}).then(res => {
				Object.keys(res).map(k => (res[k] = res[k] ?? ''))

				this.title = `${res.memberName} / ${res.hottId} / ${res.currentInstaId}`
				this.input.startDate = new Date(res.startDate)
				this.input.startTime = res.startDate.substr(11)
				this.input.endDate = new Date(res.endDate)
				this.input.endTime = res.endDate.substr(11)
				this.input.dealStatus = res.statusName
				this.input.chargeAdd = res.chargeAdd
				this.input.isSupportAdvertisement = res.isSupportAdvertisement
				this.input.isAutoEncore = res.isAutoEncore

				switch (res.dealType) {
					case 1:
						res.dealType = '내돈내산'
						break
					case 2:
						res.dealType = '선제안'
						break
					case 0:
					default:
						res.dealType = '일반'
				}
				switch (res.deliveryFreePrice) {
					case 99999999:
						res.deliveryFreePrice = '무조건 부과'
						break
					case -1:
						res.deliveryFreePrice = '상품별 상이'
						break
					case 0:
						res.deliveryFreePrice = '무료배송'
						break
					default:
						res.deliveryFreePrice = comma(res.deliveryFreePrice) + '원 이상 무료배송'
				}

				this.menuTabType.map(k => (res.type = res.type.replace(k.value, k.label)))

				this.dealInfo = [
					{ title: '딜요청번호', cont: res.idx },
					{ title: '딜번호', cont: res.openDealNum },
					{
						title: '딜명',
						cont: res.dealName,
					},
					{
						title: '딜링크',
						model: 'url',
						cont: res.openDealNum ? `${dealHost}/${res.openDealNum}` : '',
					},
					{ title: '딜시작일', model: 'dateTime', cont: 'start' },
					{ title: '딜 종료일', model: 'dateTime', cont: 'end' },
					{ title: '딜신청일', cont: res.requestDate },
					{ title: '딜수락일', cont: res.dealAcceptDay },
					{ title: '딜요청상태', model: 'dealStatus', cont: '' },
					{ title: '딜취소일자', cont: res.dealCancelDay },
					{ title: '딜구분', cont: res.encoreType },
					{
						title: '딜수수료 추가변경',
						model: 'chargeAdd',
					},
					{ title: '신청탭', cont: getOptsLabel(this.menuTabType, res.menuTabType) },
					{ title: '광고지원 여부', model: 'switch', cont: 'isSupportAdvertisement' },
					{ title: '딜 유형', cont: res.dealType },
					{ title: '앵콜딜 자동 열림', model: 'switch', cont: 'isAutoEncore' },
					{ title: '수동딜여부', cont: res.isAutoDeal ? 'X' : 'O' },
					{ title: '부모 딜 번호', cont: res.parentDealNum },
				]

				this.hottistInfo = [
					{ title: '이름', cont: res.memberName },
					{ title: '핫티스트 구분', cont: getOptsLabel(this.hottistType, res.hottistType) },
					{ title: '핫트아이디', cont: res.hottId },
					{ title: '핫티스트 등급', cont: res.hottistClass },
					{ title: '홍보채널1', model: 'url', cont: res.snsLink },
					{
						title: '콘텐츠 카테고리 1',
						cont: getOptsLabel(this.contentCategoryOpts, res.contentCategory1),
					},
					{ title: '홍보채널2', model: 'url', cont: res.snsLink2 },
					{
						title: '콘텐츠 카테고리 2',
						cont: getOptsLabel(this.contentCategoryOpts, res.contentCategory2),
					},
					{ title: '홍보채널3', model: 'url', cont: res.snsLink3 },
					{
						title: '콘텐츠 카테고리 3',
						cont: getOptsLabel(this.contentCategoryOpts, res.contentCategory3),
					},
					{
						title: '팔로워수',
						cont: res.follower,
					},
					{
						title: '콘텐츠 퀄리티',
						cont: getOptsLabel(this.contentQualityOpts, res.contentQuality),
					},
				]
				this.productGroupInfo = [
					{ title: 'idx', cont: res.productGroupIdx },
					{ title: '파트너사', cont: res.companyName },
					{
						title: '브랜드',
						cont: res.brandName,
					},
					{ title: '상품그룹명', cont: res.productGroupName },
					{ title: '소비자가', cont: comma(res.customerPrice + '원') },
					{ title: '핫딜가', cont: comma(res.salePrice + '원') },
					{ title: '배송비', cont: comma(res.deliveryPrice + '원') },

					{ title: '무료배송 조건', cont: res.deliveryFreePrice },
					{ title: '렌탈 적용 총 비용', cont: comma(res.rentalPriceInterest + '원') },
					{ title: '인플루언서 수수료(%)', cont: comma(res.fee) },
					{ title: '렌탈 총 개월 수', cont: comma(res.rentalMonth + '개월') },
					{ title: '렌탈 월 비용', cont: comma(res.rentalMonthPrice + '원') },
					{ title: '상품그룹 타입', cont: getOptsLabel(this.productDeliveryType, res.productDeliveryType) },
					{ title: '렌탈 적용 월 비용', cont: comma(res.rentalMonthPriceInterest + '원') },
					{ title: '제주도 배송비', cont: comma(res.jejuDeliveryPrice + '원') },
					{ title: '렌탈 구분', cont: res.productType },
					{ title: '묶음 배송 개수', cont: comma(res.bundleDeliveryCount + '개') },
					{ title: '등급', cont: res.productGroupClass },
					{ title: '카테고리', cont: res.type },
					{ title: '등록일', cont: res.enrollDate },
				]

				this.productInfo = [
					{ title: '안심번호', cont: res.virtualPhoneNumber },
					{ title: '인플루언서 주소', cont: `${res.address} ${res.address2}`, isFull: 1 },
					{ title: '인플루언서 우편번호', cont: res.postCode },
					{
						title: '협찬품 배송 주소',
						cont: `${res.sponsoredAddress} ${res.sponsoredAddress2}`,
						isFull: 1,
					},
					{ title: '협찬품 배송 우편번호', cont: res.sponsoredPostCode },
					{
						title: '협찬품 옵션가격',
						cont: comma(res.dealSalePrice + '원'),
					},
					{ title: '상품번호', cont: res.productNum },
					{ title: '상품명', cont: res.productName },
					{ title: '옵션1', cont: res.productOption },
					{ title: '옵션2', cont: res.productOption2 },
					{ title: '협찬 운송장 번호', cont: res.deliveryNum },
					{ title: '협찬 운송장 등록일', cont: res.deliveryRegDate },
					{ title: '협찬 택배사', cont: getOptsLabel(this.deliveryCompany, res.deliveryCompany) },
					{ title: '협찬 배송 상태', cont: getOptsLabel(this.deliveryStatus, res.deliveryState) },
					{ title: '협찬 배송 시작일', cont: res.deliveryStartDate },
					{ title: '협찬 배송 완료일', cont: res.deliveryCompleteDate },
				]
			})
		},
		editDealStatus(isCheckRestrictedDate = true, afterFunc) {
			//상태 변경 버튼으로 들어올 수도 있고, 수정 버튼으로 들어올 수도 있다
			//상태 변경 버튼으로 들어왔다면 딜의 원본 시작일을 체크해야 하고, 수정 버튼으로 들어온다면 딜의 바뀌
			//수정 버튼으로 들어올 때는 수정단에서 체크하고 들어오는 걸로. 여기에는 false가 들어올 것
			if (this.input.dealStatus.value) {
				if (isCheckRestrictedDate && this.input.dealStatus.value != 'O')
					//상태 변경에서 들어왔더라도 딜 수락이 아니면 딜 불가기간으로 막으면 안 된다
					isCheckRestrictedDate = false
				const func = () => {
					this.p.updateDealStatus(
						{
							dealStatusDtoList: [
								{
									idx: this.originItem.idx,
									dealStatus: getDropdownValue(this.input.dealStatus),
									isAccepted: this.input.dealStatus.inputAccepted,
									chargeAdd: this.input.chargeAdd,
								},
							],
						},
						() => {
							this.input.dealStatus = this.input.dealStatus.label
							if (typeof afterFunc == 'function') afterFunc()
						}
					)
				}
				if (isCheckRestrictedDate) this.p.checkRestrictedDate([this.originItem], true).then(() => func())
				else func()
			} else alert.w('딜 요청 상태가 초기 상태와 동일합니다')
		},
		edit() {
			const input = this.input,
				startDate = input.startDate.get_time('d'),
				startDateTime = startDate + 'T' + input.startTime,
				endDate = input.endDate.get_time('d'),
				endDateTime = endDate + 'T' + input.endTime
			if (startDateTime > endDateTime) alert.w('시작일이 종료일보다 큽니다')
			else {
				if (!/[0-2]\d(:[0-5]\d){2}/.test(startDateTime)) alert.w('딜 시작 시간의 입력값이 시간 형식이 아닙니다')
				else if (!/[0-2]\d(:[0-5]\d){2}/.test(endDateTime))
					alert.w('딜 종료 시간의 입력값이 시간 형식이 아닙니다')
				else {
					this.p.checkRestrictedDate([{ ...this.originItem, startDate, endDate }], true).then(() => {
						const func = () => {
							const data = {
								idx: this.originItem.idx,
								startDateTime,
								endDateTime,
								isSupportAdvertisement: input.isSupportAdvertisement,
								isAutoEncore: input.isAutoEncore,
							}
							let error = []
							if (this.isEditableCharge) {
								if (isNum(input.chargeAdd) && input.chargeAdd >= -99 && input.chargeAdd <= 99)
									data.chargeAdd = input.chargeAdd
								else error.push('딜수수료 형식이 잘못되었습니다.\r\n-99 ~ 99')
							}
							if (error.length) alert.w(error)
							else
								putApi('api/backoffice/dealHandle/updateDeal', data).then(() => {
									alert.s('딜 정보 변경에 성공했습니다', 500)
									setTimeout(() => (this.isShow = false), 500)
									reload(this, true)
								})
						}

						if (input.dealStatus.value) this.editDealStatus(false, func)
						else func()
					})
				}
			}
		},
	},
	computed: {
		isEditableCharge() {
			const input = this.input,
				target = input.dealStatus
			return (
				(target == '딜 수락' || (target.label && target.label == '딜 수락')) &&
				new Date(input.startDate) > this.now
			)
		},
		dealStatusOpts() {
			let isAccepted = ''
			switch (this.originItem.isComplete) {
				case 'N':
					isAccepted = false
					break
				case 'O':
					isAccepted = true
					break
			}
			return this.statusOpts.filter(v => v.inputAccepted === isAccepted)
			//statusOpts.slice(2)
			//originItem
		},
	},
}
</script>
